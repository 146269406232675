import React from "react";

const icons = [
  {
    src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nextjs/nextjs-original.svg",
    alt: "Next.js",
  },
  {
    src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
    alt: "React",
  },
  {
    src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/dot-net/dot-net-original.svg",
    alt: ".NET",
  },
  {
    src: "https://www.vectorlogo.zone/logos/nodejs/nodejs-icon.svg",
    alt: "Node.js",
  },
  {
    src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/azure/azure-original.svg",
    alt: "Azure",
  },
  {
    src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/microsoftsqlserver/microsoftsqlserver-plain.svg",
    alt: "SQL Server",
  },
  {
    src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original.svg",
    alt: "MySQL",
  },
];

const Tecnologies = () => {
  return (
    <section
      id="tecnologias"
      className="py-8 relative overflow-hidden h-screen"
    >
      <h5 className="text-3xl font-bold mb-4">Tecnologías</h5>
      <div className="h-full mx-auto flex flex-col gap-8 items-center justify-center md:flex-row">
        {/* Texto a la izquierda */}
        <div className="md:w-1/2 p-4">
          <h2 className="text-lg font-light text-center md:text-3xl md:text-start">
            Combinando las últimas tecnologías con las mejores prácticas, en
            Arkane somos expertos en ofrecer soluciones personalizadas que
            pueden llevar su negocio al siguiente nivel.
          </h2>
        </div>

        {/* Listado de tecnologías a la derecha */}
        {/* <div className="md:w-1/2 p-4 relative w-full h-full flex items-center justify-center">
          <div className="carousel">
            {icons.map((icon, index) => (
              <div key={index} className="carousel__item ">
                <div className="carousel__item2">
                <img src={icon.src} alt={icon.alt} className="w-20 h-20" />
                <span className="text-sm">{icon.alt}</span>
                </div>
              </div>
            ))}
          </div>
        </div> */}
        <div className="items-start md:w-1/2 p-4 relative w-full h-full flex md:items-center justify-center">
         <div className="carousel">
            <div className="carousel__track">
              {icons.map((icon, index) => (
                <div key={index} className="carousel__item flex flex-col justify-center items-center gap-4">
                  <img src={icon.src} alt={icon.alt} className="w-20 h-20" />
                  <span className="text-sm">{icon.alt}</span>
                </div>
              ))}
              {icons.map((icon, index) => (
                <div key={index + icons.length} className="carousel__item flex flex-col justify-center items-center gap-4">
                  <img src={icon.src} alt={icon.alt} className="w-20 h-20" />
                  <span className="text-sm">{icon.alt}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Tecnologies;
