import React from "react";
import { FaCode, FaGlobe, FaMobileAlt, FaPaintBrush, FaRobot, FaTools } from "react-icons/fa";
import "./services.css";

const Experience = () => {
  const services = [
    {
      name: "Desarrollo de software a medida",
      icon: <FaCode className="text-4xl" />,
      description: "Creamos software personalizado que se adapta a las necesidades específicas de su negocio."
    },
    {
      name: "Desarrollo Web",
      icon: <FaGlobe className="text-4xl" />,
      description: "Desarrollamos sitios web modernos y responsivos para mejorar su presencia en línea."
    },
    {
      name: "Desarrollo Móvil",
      icon: <FaMobileAlt className="text-4xl" />,
      description: "Creamos aplicaciones móviles intuitivas y funcionales para Android e iOS."
    },
    {
      name: "Diseño UX/UI",
      icon: <FaPaintBrush className="text-4xl" />,
      description: "Diseñamos interfaces de usuario atractivas y fáciles de usar para mejorar la experiencia del usuario."
    },
    {
      name: "Chatbots y Asistentes Virtuales",
      icon: <FaRobot className="text-4xl" />,
      description: "Implementamos chatbots y asistentes virtuales para mejorar la interacción con los usuarios."
    },
    {
      name: "Soporte y mantenimiento",
      icon: <FaTools className="text-4xl" />,
      description: "Ofrecemos soporte continuo y mantenimiento para asegurar el funcionamiento óptimo de sus aplicaciones."
    },
  ];

  return (
    <section id="servicios">
      <h5 className="text-xl">Servicios</h5>
      <article className="flex flex-col justify-center w-full h-full gap-3">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <div key={index} className="group perspective">
              <div className="relative w-full h-48 rounded-lg shadow-lg transform-style-3d group-hover:rotate-y-180 transition-transform duration-700">
                <div className="absolute w-full h-full bg-bg-variant backface-hidden flex flex-col items-center justify-center p-4">
                  {service.icon}
                  <p className="text-center mt-2">{service.name}</p>
                </div>
                <div className="absolute w-full h-full bg-gray-900 text-white backface-hidden flex items-center justify-center p-4 rotate-y-180">
                  <p className="text-center">{service.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </article>
    </section>
  );
};

export default Experience;