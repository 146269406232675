import React from "react";
import "./header.css";
import ARKANE from "../../assets/solo-letras.png";
import ARKANE_MOBILE from "../../assets/logo-y-letras-sin-fondo.png";

const Header = () => {
  return (
    <header>
      <div className="container w-full h-full flex flex-col-reverse items-center justify-center md:flex md:flex-row md:gap-20">
        <div className="flex flex-col justify-center items-start gap-4 w-full h-full text-xl">
          <div className="flex flex-col justify-center gap-8 items-center h-full w-full">
            <div className="flex justify-center items-end w-full">
            {/* Imagen para desktop */}
              <img
                src={ARKANE}
                alt="arkane"
                className="hidden md:block" //w-96 h-auto object-cover"
              />
              {/* Imagen para mobile */}
              <img
                src={ARKANE_MOBILE}
                alt="arkane mobile"
                className="block md:hidden" //w-96 h-auto object-cover"
              />
            </div>
          <div className="flex flex-col justify-start items-center w-full text-light text-xl md:flex-row md:justify-center md:items-start md:gap-2 md:text-4xl">
            <h5>
              Soluciones tecnológicas{" "}
            </h5>
              <span class="overflow-hidden typed-[innovadoras;personalizadas;a_tu_medida] typed-caret"></span>
          </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
