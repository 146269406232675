import React from 'react'
import "./footer.css"
import { BsLinkedin } from 'react-icons/bs'
import { BsGithub } from 'react-icons/bs'

const Footer = () => {
  return (
    <footer className='bg-gray-900'>
      <a href="#" className='footer__logo'>Arkane Soft</a>
      <ul className='permalinks'>
        <li><a href="#nosotros">Nosotros</a></li>
        <li><a href="#servicios">Servicios</a></li>
        <li><a href="#tecnologias">Tecnologias</a></li>
        <li><a href="#contacto">Contacto</a></li>
      </ul>

      <div className="footer__copyright">
        <small>&copy; Arkane Soft. Todos los derechos reservados</small>
      </div>
    </footer>
  )
}

export default Footer