import React, { useState } from "react";
import AS from "../../assets/as.png";
import { FaLightbulb, FaRegLightbulb } from "react-icons/fa"; // Asegúrate de importar los íconos que usas

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className={`fixed top-0 z-50 w-full h-20 bg-gray-900 md:bg-gradient-to-r from-purple-950 from-0% to-blue-950 to-100%`}>
      <div className="flex justify-between items-center p-4 h-full mx-auto md:justify-around">
        {/* Logo */}
        <a href="#" className="text-2xl font-bold flex items-center">
          <img src={AS} alt="Logo" className="h-20 w-20" />
        </a>

        {/* Mobile Menu Button */}
        <div className="md:hidden flex items-center">
          <button onClick={toggleMenu} className="text-2xl text-white">
            <span role="img" aria-label="Menu">
              ☰
            </span>
          </button>
        </div>

        {/* Mobile Menu */}
        <div
          className={`fixed top-0 right-0 h-full bg-gray-900 text-white shadow-lg transition-transform duration-300 ${
            isMenuOpen ? "translate-x-0" : "translate-x-full"
          } md:hidden w-3/4`}
        >
          <button
            onClick={toggleMenu}
            className="absolute top-4 right-4 text-2xl text-white"
          >
            ×
          </button>
          <ul className="flex flex-col items-center mt-20">
            <li className="p-4">
              <a
                href="#nosotros"
                className="hover:underline"
                onClick={() => toggleMenu(false)}
              >
                Nosotros
              </a>
            </li>
            <li className="p-4">
              <a
                href="#servicios"
                className="hover:underline"
                onClick={() => toggleMenu(false)}
              >
                Servicios
              </a>
            </li>
            <li className="p-4">
              <a
                href="#tecnologias"
                className="hover:underline"
                onClick={() => toggleMenu(false)}
              >
                Tecnologías
              </a>
            </li>
            <li className="p-4">
              <a
                href="#contacto"
                className="hover:underline"
                onClick={() => toggleMenu(false)}
              >
                Contacto
              </a>
            </li>
          </ul>
        </div>

        {/* Desktop Menu */}
        <div className="hidden md:flex md:items-center md:gap-4 text-white">
          <ul className="flex space-x-4">
            <li>
              <a href="#nosotros" className="hover:underline">
                Nosotros
              </a>
            </li>
            <li>
              <a href="#servicios" className="hover:underline">
                Servicios
              </a>
            </li>
            <li>
              <a href="#tecnologias" className="hover:underline">
                Tecnologías
              </a>
            </li>
            <li>
              <a href="#contacto" className="hover:underline">
                Contacto
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
