import React from "react";
import Header from "./components/header/Header";
import About from "./components/about/About";
import Services from "./components/services/Services";
import Tecnologies from "./components/tecnologies/Tecnologies";
import Contact from "./components/contact/Contact";
import Layout from "./components/layout/Layout";

const App = () => {
  return (
    <Layout>
      <div className="min-h-screen px-6 md:px-52 bg-gradient-to-r from-purple-950 from-0% to-blue-950 to-100%">
        <Header />
        <About />
        <Services />
        <Tecnologies />
        <Contact />
      </div>
    </Layout>
  );
};

export default App;
