import React from "react";
import "./about.css";
import { FaUsers, FaBullseye, FaEye } from 'react-icons/fa';

const About = () => {
  return (
    <section id="nosotros">
      <h5 className="text-xl">Acerca de</h5>
      <div className="flex justify-center items-center pt-4 h-full w-full">
        <div className="flex flex-col justify-around items-center gap-4 w-full h-full md:flex md:flex-row">
          <article className="about__card flex flex-col w-full h-full gap-3 md:h-2/3">
            <div className="flex justify-center items-start md:h-1/3">
              <FaUsers className="about__icon" />
              <h5 className="flex-grow text-center">Nosotros</h5>
            </div>
            <div className="flex flex-col justify-start items-center h-full w-full font-light">
            <small>
              En Arkane Soft, somos un equipo apasionado por la tecnología y el
              desarrollo de software. Desde nuestra creación, hemos trabajado
              con una visión clara: ofrecer soluciones tecnológicas que no solo
              resuelvan problemas, sino que también transformen la forma en que
              las empresas operan y crecen. Nos especializamos en crear
              aplicaciones y sistemas a medida que se adaptan perfectamente a
              las necesidades específicas de cada cliente, garantizando
              resultados excepcionales y sostenibles.
            </small>
            </div>
          </article>

          <article className="about__card flex flex-col w-full h-full gap-3 md:h-2/3">
            <div className="flex justify-center items-start md:h-1/3">
              <FaBullseye className="about__icon" />
              <h5 className="flex-grow text-center">Misión</h5>
            </div>
            
            <div className="flex flex-col justify-start items-center h-full w-full font-light">
            <small>
              En Arkane Soft, nuestra misión es diseñar y desarrollar soluciones
              de software innovadoras y personalizadas que impulsen el
              crecimiento y la eficiencia de nuestros clientes. Nos
              comprometemos a brindar productos de alta calidad que superen las
              expectativas, utilizando tecnología de vanguardia y un enfoque
              centrado en el cliente.
            </small>
            </div>
          </article>

          <article className="about__card flex flex-col w-full h-full gap-3 md:h-2/3">
            <div className="flex justify-center items-start md:h-1/3">
              <FaEye className="about__icon" />
              <h5 className="flex-grow text-center">Visión</h5>
            </div>
            <div className="flex flex-col justify-start items-center h-full w-full font-light">
            <small>
              Nuestra visión es convertirnos en un referente global en el
              desarrollo de software, conocido por nuestra excelencia,
              creatividad e impacto en el éxito de las empresas que servimos.
              Aspiramos a liderar la transformación digital, creando un futuro
              donde la tecnología esté al servicio del progreso humano y
              empresarial.
            </small>
            </div>
          </article>
        </div>
      </div>
    </section>
  );
};

export default About;
